import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

$(document).ready(function () {
  if (document.querySelector(".js-dropdown")) {
    const btns = document.querySelectorAll(".js-dropdown");

    btns.forEach((btn) => {
      const dropdown = btn.querySelector(".header__dropdown");
      let timer;

      btn.addEventListener("click", () => {
        btn.classList.toggle("open");
        dropdown.classList.toggle("open");
      });

      btn.addEventListener("mouseenter", () => {
        clearTimeout(timer);
      });

      btn.addEventListener("mouseleave", () => {
        timer = setTimeout(() => {
          btn.classList.remove("open");
          dropdown.classList.remove("open");
        }, 1000);
      });
    });
  }

  if (document.querySelector(".typed-element")) {
    const typedText = document.querySelectorAll(".typed-text");
    let stringsArr = [];
    typedText.forEach((string) => {
      stringsArr = [...stringsArr, string.innerHTML];
    });
    const typed = new Typed(".typed-element", {
      strings: [...stringsArr],
      typeSpeed: 40,
      backSpeed: 20,
      loop: true,
    });
  }

  $("a").on("click", function (event) {
    if (!event.target.classList.contains("ui-tabs-anchor")) {
      if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top,
          },
          800,
          function () {
            window.location.hash = hash;
          }
        );
      }
    }
  });



  //tilt
  const tiltElements = document.querySelectorAll(".js-tilt");

  if (tiltElements.length > 0 && window.innerWidth > 1365) {
    VanillaTilt.init(tiltElements, {
      max: 15,
      speed: 1000,
      reset: true,
      transition: true,
      "reset-to-start": true,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      glare: false,
    });
  }
  // end tilt

  // burger-menu
  const burgerMenu = (menuSelector, burgerSelector) => {
    const menuElem = document.querySelector(menuSelector),
      layoutElem = document.querySelector(".header__layout"),
      burgerElem = document.querySelector(burgerSelector);

    const removeClass = () => {
      menuElem.classList.remove("open");
      document.body.classList.remove("no-scroll");
    };
    removeClass();

    menuElem.querySelectorAll("a").forEach((i) => {
      i.addEventListener("click", (e) => {
        if (menuElem.classList.contains("open")) {
          removeClass();
        }
      });
    });

    burgerElem.addEventListener("click", () => {
      if (menuElem.classList.contains("open")) {
        removeClass();
      } else {
        menuElem.classList.add("open");
        document.body.classList.add("no-scroll");
      }
    });

    layoutElem.addEventListener("click", () => {
      removeClass();
    });

    window.addEventListener("resize", function () {
      if (window.innerWidth > 1200) {
        removeClass();
      }
    });
  };

  burgerMenu(".header", ".header__burger");
  // end burger-menu

  // gsap
  gsap.registerPlugin(ScrollTrigger);
  // if (window.innerWidth > 568) {
  //   let speed = 15;
  //   if (window.innerWidth > 1023) {
  //     speed = 20;
  //   }
  //   let tl = gsap.timeline();
  //   const scrollingText = document.querySelectorAll(".muve__text");
  //   if (scrollingText.length > 0) {
  //     scrollingText.forEach((text, i) => {
  //       const val = i % 2 === 0 ? -speed : speed;

  //       tl.to(text, {
  //         xPercent: val,
  //         scrollTrigger: {
  //           trigger: text,
  //           scrub: 0,
  //         },
  //       });
  //     });
  //   }
  // }

  ScrollTrigger.create({
    start: "50 bottom",
    trigger: ".muve-svg",
    end: 99999,
    onEnter: () => $(".muve__decor").addClass("anim"),
  });

  // end gsap

  // tabs
  $(".care__tab-box").tabs({
    show: { effect: "fadeIn", duration: 150 },
    hide: { effect: "fadeOut", duration: 150 },
  });
  $(".about__tab-box").tabs({
    show: { effect: "fadeIn", duration: 150 },
    hide: { effect: "fadeOut", duration: 150 },
  });
  // end tabs

  // marquee
  $(".marquee").marquee({
    duration: 30000,
    duplicated: true,
    delayBeforeStart: 100,
    startVisible: true,
  });
  $(".marquee-revers").marquee({
    duration: 30000,
    duplicated: true,
    direction: "right",
    delayBeforeStart: 100,
    startVisible: true,
  });
  // end marquee

  // swiper
  if (document.querySelector(".jobs__slider")) {
    MetaSwiper(`.jobs__slider`, {
      loop: false,
      slidesPerView: "auto",
      spaceBetween: 15,
      speed: 400,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".jobs__btn-prev",
        nextEl: ".jobs__btn-next",
      },
      breakpoints: {
        1024: {
          slidesPerView: "auto",
          spaceBetween: 30,
        },
        1920: {
          slidesPerView: "auto",
          spaceBetween: 45,
        },
      },
    });
  }

  if (document.querySelector(".sectors__slider")) {
    new Swiper(`.sectors__slider`, {
      slidesPerView: 1,
      spaceBetween: 15,
      speed: 400,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".sectors__btn-prev",
        nextEl: ".sectors__btn-next",
      },
      breakpoints: {
        658: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
    });
  }

  if (document.querySelector(".about__slider")) {
    const aboutSliders = document.querySelectorAll(".about__slider");

    const sliderFirstId = aboutSliders[0].classList[2].split("--")[1];
    const sliderSecondId = aboutSliders[1].classList[2].split("--")[1];

    MetaSwiper(`.about__slider--${sliderFirstId}`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 0,
      observer: true,
      observeParents: true,
      speed: 400,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: `.about__btn-prev--${sliderFirstId}`,
        nextEl: `.about__btn-next--${sliderFirstId}`,
      },
      breakpoints: {
        568: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1124: {
          slidesPerView: 3,
          spaceBetween: 52,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 75,
        },
      },
    });

    const aboutSlider = new Swiper(`.about__slider--${sliderSecondId}`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 0,
      observer: true,
      observeParents: true,
      speed: 400,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: `.about__btn-prev--${sliderSecondId}`,
        nextEl: `.about__btn-next--${sliderSecondId}`,
      },
      breakpoints: {
        568: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1124: {
          slidesPerView: 3,
          spaceBetween: 52,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 75,
        },
      },
    });

    document.querySelector("#ui-id-4").addEventListener("click", () => {
      setTimeout(() => {
        SafariSwiper(aboutSlider);
        aboutSlider.autoplay.start();
      }, 500);
    });
  }

  if (document.querySelector(".care__slider--1")) {
    MetaSwiper(`.care__slider--1`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 20,
      observer: true,
      observeParents: true,
      // speed: 400,
      // autoplay: {
      //   delay: 4000,
      //   disableOnInteraction: false,
      //   pauseOnMouseEnter: true,
      // },
      navigation: {
        prevEl: ".care__btn-prev--1",
        nextEl: ".care__btn-next--1",
      },
      breakpoints: {
        568: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1023: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
        1920: {
          slidesPerView: 4,
        },
      },
    });
  }

  if (document.querySelector(".care__slider--2")) {
    const careSlider = new Swiper(`.care__slider--2`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 20,
      observer: true,
      observeParents: true,
      // speed: 400,
      // autoplay: {
      //   delay: 4000,
      //   disableOnInteraction: false,
      //   pauseOnMouseEnter: true,
      // },
      navigation: {
        prevEl: ".care__btn-prev--2",
        nextEl: ".care__btn-next--2",
      },
      breakpoints: {
        568: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1023: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
        1920: {
          slidesPerView: 4,
        },
      },
    });

    document.querySelector("#ui-id-2").addEventListener("click", () => {
      setTimeout(() => {
        SafariSwiper(careSlider);
        careSlider.autoplay.start();
      }, 500);
    });
  }

  if (document.querySelector(".benefits__slider")) {
    MetaSwiper(`.benefits__slider`, {
      loop: false,
      slidesPerView: "auto",
      spaceBetween: 10,
      speed: 400,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".benefits__btn-prev",
        nextEl: ".benefits__btn-next",
      },
      breakpoints: {
        1024: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1920: {
          slidesPerView: "auto",
          spaceBetween: 30,
        },
      },
    });
  }

  const careTypeSlider = document.querySelectorAll(".care-type-tab-slider");

  if (careTypeSlider.length) {
    careTypeSlider.forEach((slider) => {
      new Swiper(slider, {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          prevEl: `.care-type-slider__nav[data-tab-id="${slider.dataset.tabId}"] .care-type-slider__btn.prev`,
          nextEl: `.care-type-slider__nav[data-tab-id="${slider.dataset.tabId}"] .care-type-slider__btn.next`,
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
          1280: {
            spaceBetween: 20,
            slidesPerView: 3,
          },
        },
      });
    });
  }
  // end swiper

  const careTypeSection = document.querySelector(".care-type");

  if (careTypeSection) {
    const tabs = careTypeSection.querySelectorAll(".care-type__tab");
    const firstNav = document.querySelectorAll('.care-type-slider__nav');
    let activeTab = [...tabs].find((item) => item.classList.contains("active"))
      .dataset.tabId;

    firstNav[1].classList.add("hidden");

    tabs.forEach((tab) => [
      tab.addEventListener("click", () => {
        const { tabId } = tab.dataset;

        const prevTab = document.querySelector(
          `.care-type__tab[data-tab-id="${activeTab}"]`
        );
        const prevSliderNav = document.querySelector(
          `.care-type-slider__nav[data-tab-id="${activeTab}"]`
        );
        const prevTabContent = document.querySelector(
          `.care-type__tab-content[data-tab-id="${activeTab}"]`
        );

        const sliderNav = document.querySelector(
          `.care-type-slider__nav[data-tab-id="${tabId}"]`
        );
        const tabContent = document.querySelector(
          `.care-type__tab-content[data-tab-id="${tabId}"]`
        );

        prevTab.classList.remove("active");
        prevSliderNav.classList.add("hidden");
        prevTabContent.classList.add("hidden");

        tab.classList.add("active");
        sliderNav.classList.remove("hidden");
        tabContent.classList.remove("hidden");

        activeTab = tabId;
      }),
    ]);
  }

  const tabsSection = document.querySelector(".tabs");

  if (tabsSection) {
    const tabSliders = tabsSection.querySelectorAll(".tab-slider");

    tabSliders.forEach((slider) => {
      new Swiper(slider, {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          prevEl: `.tab-slider__nav[data-tab-id="${slider.dataset.tabId}"] .tab-slider__btn.prev`,
          nextEl: `.tab-slider__nav[data-tab-id="${slider.dataset.tabId}"] .tab-slider__btn.next`,
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
          1280: {
            spaceBetween: 20,
            slidesPerView: 3,
          },
        },
      });
    });

    let activeTab = 0;

    const tabs = tabsSection.querySelectorAll(".tabs__tab");
    tabs.forEach((tab) => [
      tab.addEventListener("click", () => {
        const { tabId } = tab.dataset;
        const prevTab = tabsSection.querySelector(
          `.tabs__tab[data-tab-id="${activeTab}"]`
        );
        const prevSliderNav = tabsSection.querySelector(
          `.tab-slider__nav[data-tab-id="${activeTab}"]`
        );
        const prevTabContent = tabsSection.querySelector(
          `.tabs__tab-content[data-tab-id="${activeTab}"]`
        );

        const sliderNav = tabsSection.querySelector(
          `.tab-slider__nav[data-tab-id="${tabId}"]`
        );
        const tabContent = tabsSection.querySelector(
          `.tabs__tab-content[data-tab-id="${tabId}"]`
        );

        prevTab.classList.remove("active");
        prevSliderNav.classList.add("hidden");
        prevTabContent.classList.add("hidden");

        tab.classList.add("active");
        sliderNav.classList.remove("hidden");
        tabContent.classList.remove("hidden");

        activeTab = tabId;
      }),
    ]);
  }

  // nice-select
  $(".form-work__select").niceSelect();
  $(".callback__select").niceSelect();

  const selectWrap = document.querySelectorAll(".form-work__select .list");

  if (!!selectWrap.length) {
    selectWrap.forEach((select) => new SimpleBar(select, { autoHide: true }));
  }
  // end nice-select

  AOS.init({
    offset: -250,
    delay: 0,
    duration: 400,
    easing: "ease",
    once: true,
  });
});
